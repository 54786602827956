var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"email_observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('core-section',{attrs:{"id":"lets-talk"}},[_c('core-heading',[_vm._v("Let's talk.")]),_c('v-col',{staticClass:"mb-4 pr-md-5",attrs:{"cols":"12","md":"5"}},[_c('core-subheading',{staticClass:"text-uppercase"},[_vm._v(" What can I do for you? ")]),_c('core-text',{staticClass:"mb-5"},[_vm._v(" Please reach out if you have any questions or want to connect for possible work opportunities. I'm looking forward to hearing from you! ")]),_c('core-subheading',{staticClass:"text-uppercase mb-3"},[_vm._v(" Send me a Message ")]),_c('v-form',[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"solo-inverted":"","flat":"","label":"Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('span',{staticClass:"validation-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"solo-inverted":"","flat":"","label":"Email"},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}}),_c('span',{staticClass:"validation-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"subject","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"solo-inverted":"","flat":"","label":"Subject"},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}}),_c('span',{staticClass:"validation-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"solo-inverted":"","flat":"","label":"Message"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('span',{staticClass:"validation-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('v-btn',{staticClass:"ma-0",attrs:{"color":"primary","disabled":invalid || _vm.sendingEmail,"loading":_vm.sendingEmail},on:{"click":_vm.sendEmail}},[_vm._v(" Contact Me ")])],1)],1),_c('v-col',{staticClass:"text-left",attrs:{"cols":"12","md":"7"}},[_c('core-subheading',{staticClass:"text-uppercase"},[_vm._v(" Social Contacts ")]),_c('social-contacts'),_c('v-list',{staticClass:"transparent",attrs:{"three-line":""}},[_c('v-list-item',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","size":"48"}},[_vm._v(" mdi-map-marker ")]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"primary--text"},[_vm._v(" Address ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.schema.basics.location.address)),_c('br'),_vm._v(" "+_vm._s(_vm.schema.basics.location.city)+", "+_vm._s(_vm.schema.basics.location.postalCode)+" ")])],1)],1),_c('v-list-item',[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"48","color":"primary"}},[_vm._v(" mdi-email ")]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"primary--text"},[_vm._v(" Email ")]),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.schema.basics.email)}})],1)],1),_c('v-list-item',[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"48","color":"primary"}},[_vm._v(" mdi-phone ")]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"primary--text"},[_vm._v(" Phone ")]),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.schema.basics.phone)}})],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }